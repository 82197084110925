import { supabase } from "../../../supabaseClient";

const DEFAULTS = {
  page: 1,
  limit: 10,
  sortBy: "distance",
  order: "desc",
};

const buildQuery = (query, filters) => {
  const { userId, isLiked, isVisited, likedYou, isNotLiked } = filters;

  if (userId) query = query.eq("id", userId);
  if (isLiked) query = query.eq("liked", true);
  if (isVisited) query = query.eq("visited", true);
  if (likedYou) query = query.eq("liked_you", true);
  if (isNotLiked) query = query.eq("liked", false);

  return query;
};

const handleError = (error, signal, page) => {
  if (signal?.aborted) {
    console.warn("Requête annulée : signal reçu.");
    return {
      items: [],
      total: 0,
      hasNextPage: false,
      currentPage: page,
    };
  }

  console.error("Erreur lors de la récupération des profils :", error);
  throw error;
};

export const fetchProfiles = async (options = {}) => {
  const {
    userId,
    isLiked,
    isVisited,
    likedYou,
    isNotLiked,
    isSearching,
    page = DEFAULTS.page,
    limit = DEFAULTS.limit,
    sortBy = DEFAULTS.sortBy,
    order = DEFAULTS.order,
    signal,
  } = options;

  const start = (page - 1) * limit;
  const end = start + limit - 1;

  try {
    let query = isSearching
      ? supabase.rpc("search_criteria", {}, { count: "exact" })
      : supabase.from("secure_profiles").select("*", { count: "exact" });

    query = buildQuery(query, { userId, isLiked, isVisited, likedYou, isNotLiked });

    const sortField = isVisited ? "visited_at" : sortBy;

    const { data, error, count } = await query
      .order(sortField, { ascending: order === "asc" })
      .range(start, end)
      .abortSignal(signal);

    if (error) {
      throw new Error(`Erreur lors de la récupération des profils : ${error.message}`);
    }

    return {
      items: data,
      total: count,
      hasNextPage: end < count - 1,
      currentPage: page,
    };
  } catch (error) {
    return handleError(error, signal, page);
  }
};
