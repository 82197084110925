import { useState, Suspense, lazy, useLayoutEffect } from "react";
import useSWR from "swr";
import {
  Divider,
  Flex,
  Text,
  Heading,
  Content,
  ActionGroup,
  Item,
  Tooltip,
  TooltipTrigger,
  ListView,
  Image,
  ButtonGroup,
  Button,
  IllustratedMessage,
  DialogContainer,
  Dialog,
  useDialogContainer,
} from "@adobe/react-spectrum";
import Heart from "@spectrum-icons/workflow/Heart";
import NotFound from "@spectrum-icons/illustrations/NotFound";
import Loading from "../../components/Loading";
import { useToggleLike } from "../../services/rpc_supabase/likes";
const UserView = lazy(() => import("./UserView"));

function UserListView({ isOwnProfile, secureList, onFavoriteToggle }) {
  const { toggleLike, error } = useToggleLike();
  const [isOpen, setOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const avatarsKey = "avatars-urls-cache";
  const { data: cachedAvatars = {}, error: cacheAvatarError } = useSWR(avatarsKey);
  if (cacheAvatarError) {
    console.error("Erreur de récupération du cache :", cacheAvatarError);
  }
  const handleLikeToggle = async (key, item) => {
    // Sauvegarder l'état initial
    const originalItem = { ...item };

    try {
      // OPTIMISTIC UPDATE WITH ASYNCLIST FROM ADOBE
      const updatedItem = { ...item, liked: !item.liked };
      onFavoriteToggle ? secureList.remove(key, updatedItem) : secureList.update(key, updatedItem);

      // SUPABASE UPDATE
      await toggleLike(key);
    } catch (err) {
      // Revenir à l'état initial en cas d'erreur
      secureList.update(key, originalItem);
      console.error("Erreur :", err.message + error);
    }
  };

  const renderEmptyState = () => {
    return isOwnProfile ? (
      <IllustratedMessage>
        <Heading>Bienvenue</Heading>
        <Content>
          <Flex alignItems="center" gap="size-100" direction="column">
            <Text>
              Assure-toi de compléter les informations essentielles pour accéder à toutes les fonctionnalités de
              Tramway.
            </Text>
          </Flex>
        </Content>
      </IllustratedMessage>
    ) : (
      <IllustratedMessage>
        <NotFound />
        <Heading>Pas de résultats</Heading>
        <Content>
          <Flex alignItems="center" gap="size-100" direction="column">
            <Text>Nous n'avons pas encore de membres dans Tramway.</Text>
            <Text>Tramway • courant alternatif ®</Text>
          </Flex>
        </Content>
      </IllustratedMessage>
    );
  };

  useLayoutEffect(() => {
    if (secureList.selectedKeys?.size > 0) {
      const k = secureList.selectedKeys;
      const selectedItem = secureList.getItem(k.currentKey);
      setSelectedUser(selectedItem);
      setOpen(true);
    } else {
      setSelectedUser(null);
      setOpen(false);
    }
  }, [secureList.selectedKeys, secureList.items]);

  return (
    <Flex marginBottom="size-200" width="100%" alignItems="start" gap="size-100" direction="column">
      <ListView
        zIndex="0"
        isEmphasized={true}
        aria-label={isOwnProfile ? "Mon profil" : "Les profils"}
        width="100%"
        density="spacious"
        isQuiet
        overflowMode="truncate"
        items={secureList?.items || []}
        selectionStyle="highlight"
        loadingState={secureList?.loadingState}
        onLoadMore={secureList?.loadMore}
        renderEmptyState={renderEmptyState}
        minHeight={!isOwnProfile && "size-3000"}
        selectionMode="single"
        selectedKeys={secureList?.selectedKeys}
        onSelectionChange={secureList?.setSelectedKeys}
      >
        {(item) => (
          <Item key={item?.id} textValue={item?.username} hasChildItems>
            <Image
              UNSAFE_className="profile-pic"
              objectFit="cover"
              alt="Photo principale"
              src={
                cachedAvatars[item?.avatar_url]?.signedUrl ||
                `https://api.dicebear.com/9.x/initials/svg?seed=${item?.username || "Anonymous"}`
              }
            />

            <Text aria-label={item?.flashed ? "C'est un flash mutuel entre vous deux !" : "Membre"}>
              {item?.username}
            </Text>
            <ActionGroup
              isEmphasized={item?.flashed}
              selectionMode="single"
              defaultSelectedKeys={new Set([item?.liked ? item.id : null])}
              onAction={(key) => handleLikeToggle(key, item)}
            >
              <TooltipTrigger>
                <Item key={item?.id} aria-label={item.liked ? "Exclure des favoris" : "Inclure dans les favoris"}>
                  <Heart />
                </Item>
                <Tooltip>
                  {item.liked
                    ? item.flashed
                      ? "❤️ Flash mutuel ! Exclure des favoris"
                      : "Inclure dans les favoris"
                    : "Inclure dans les favoris"}
                </Tooltip>
              </TooltipTrigger>
            </ActionGroup>
          </Item>
        )}
      </ListView>

      <Suspense fallback={<Loading />}>
        <DialogContainer type="fullscreenTakeover" onDismiss={() => setOpen(false)}>
          {isOpen && <UserView selectedUser={selectedUser} setSelectedKeys={secureList?.setSelectedKeys} />}
        </DialogContainer>
      </Suspense>
    </Flex>
  );
}

export default UserListView;
