import { useState, useEffect } from "react";
import { useAsyncList } from "@adobe/react-spectrum";
import { fetchProfiles } from "./fetchProfiles";
import { getUserInfo } from "../auth/getUserInfo";
import { useCreateSignedUrls } from "../bucket";

export const useAsyncProfiles = () => {
  const { createSignedUrls } = useCreateSignedUrls();
  const [isSearching, setIsSearching] = useState(false);
  const [isVisited, setVisited] = useState(false);
  const [isLiked, setIsLiked] = useState(false);
  const [likedYou, setLikedYou] = useState(false);
  const [isNotLiked, setIsNotLiked] = useState(false);
  const [isOwnProfile, setIsOwnProfile] = useState(false);

  // Fonction de chargement des profils qui accepte les paramètres dynamiques
  const loadProfiles = async ({
    isOwnProfile,
    isLiked,
    isVisited,
    likedYou,
    isNotLiked,
    signal,
    cursor,
    isSearching,
  }) => {
    let userId = null;
    setIsSearching(isSearching);
    setVisited(isVisited);
    setLikedYou(likedYou);
    setIsLiked(isLiked);
    setIsNotLiked(isNotLiked);
    setIsOwnProfile(isOwnProfile);
    try {
      // Récupération de l'utilisateur connecté si nécessaire
      if (isOwnProfile) {
        const user = await getUserInfo();
        if (user) {
          userId = user?.id;
        }
      }

      // Récupération des profils (données brutes)
      const profiles = await fetchProfiles({
        page: cursor || 1,
        signal,
        isOwnProfile,
        userId: isOwnProfile ? userId : undefined,
        isLiked: isLiked || false,
        isVisited: isVisited || false,
        likedYou: likedYou || false,
        isNotLiked: isNotLiked || false,
        isSearching: isSearching || false,
      });

      const items = profiles.items;

      const avatarPaths = [];
      const galleryPaths = [];

      items.forEach((profile) => {
        if (profile.avatar_url) {
          avatarPaths.push(profile.avatar_url);
        }
        if (Array.isArray(profile.gallery_urls)) {
          galleryPaths.push(...profile.gallery_urls);
        }
      });

      // Signer les URLs en arrière-plan sans bloquer le retour des données brutes
      const signUrlsPromises = [];

      if (avatarPaths.length > 0) {
        signUrlsPromises.push(createSignedUrls({ bucket: "avatars", paths: avatarPaths }));
      }
      if (galleryPaths.length > 0) {
        signUrlsPromises.push(createSignedUrls({ bucket: "gallery", paths: galleryPaths }));
      }

      // Attendre que toutes les promesses de signature soient résolues
      if (signUrlsPromises.length > 0) {
        await Promise.all(signUrlsPromises);
      }

      // Retourner les données brutes après avoir signé les URLs
      return {
        items,
        cursor: profiles.hasNextPage ? profiles.currentPage + 1 : null,
      };
    } catch (error) {
      console.error("Erreur lors de la récupération des profils:", error);
      return {
        items: [],
        cursor: null,
      };
    }
  };

  const secureList = useAsyncList({
    load: ({ signal, cursor }) =>
      loadProfiles({ signal, cursor, isSearching, isVisited, isLiked, likedYou, isNotLiked, isOwnProfile }),
  });

  useEffect(() => {
    secureList.reload();
  }, [isSearching, isVisited, isLiked, likedYou, isOwnProfile, isNotLiked]);

  return { secureList, loadProfiles, setIsSearching };
};
